body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: #2196f3;
}


body{
  
  height: 100%;
  margin: 0;
  /*background-image: url(./images/test1.jpg);*/
  background:#eff3f6;
  background-repeat: no-repeat;

  
}

.wrapper{
  display:grid;
  grid-template-rows: 30% auto 60px;
  grid-template-columns: 30% 40% 30%;
  width:100%;
  position:absolute;
  
  /* Full height */
    height: 100%; 
    min-height: 300px;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom:0;
}
.main-content{
  text-align: left;
  display:block;
  font-family: 'PT Sans',  sans-serif;
  padding:20px 20px 30px 40px;
  background:#eff3f6;




}
.appBar a {
  color: white;
}

.bgicontent{
  

    grid-row:2;
    grid-column:2;
    display:block;
/*    display: grid;
    grid-template-columns: auto 40% auto;
    grid-template-rows: auto auto auto;*/
    
   
}

.interactive-block{
  background:#eff3f6;
  padding:10px 10px 10px 10px;
 
  text-align:center;

}

.interactive-block{
 
  display:grid;
  font-family: 'PT Sans',  sans-serif;
  background:#eff3f6;
  grid-template-rows: 60% 40%;
 


}
.responses{
  display:inline;
  grid-row:2;
  
}

.r {
  display:inline-block;
  padding: 10px 10px 10px 10px;
  margin: 10px 10px 10px 10px ;
  border: 2px solid;
  border-radius: 3px;
}

.App-link {
  color: #61dafb;
}

.target-districts {
  padding-left:5%;
  padding-right:5%;
}

.section-title {
  padding-bottom:50px;
}

/*header{
  height: 50px;
  width:100%;
  background:#20232a;
  font-family: 'Dosis',  sans-serif;
  font-size: 40px;
  color: white;
  text-align: left;
  padding: 0px 20px 25px 30px;



  
}*/

header z {
    font-size:48px;
  }

  header z2 {
    font-size:28px;
  }
/*footer{

  background:#20232a;
  position:absolute;
  color: white;
  text-align: center;

  bottom:0;
  width:100%;
  height:60px;   /* Height of the footer
  } */
   




@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
   




@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

